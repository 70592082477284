/**
 * Calcula a quantidade de rateio bruto entre os items
 * @solidus - QtdRatItemBruto
 * @param isRateio - BRateia
 * @param qtdTotalItem  - QtdTotItem
 * @param qtdItem - QtdItem
 * @param valRateio - ValRat
 * @param valTotalItem - ValTotItem
 */

export function calculaQtdRateioItemBruto(
  isRateio: boolean,
  qtdTotalItem: number,
  qtdItem: number,
  valRateio: number,
  valTotalItem: number,
): number {
  if (isRateio && valRateio > 0 && valTotalItem > 0) {
    return (valRateio / qtdTotalItem) * qtdItem;
  }
  return 0;
}
