/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { add, format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export function CalcDataCondicao(
  cod_condicao: any,
  dataEmissao: any,
  dataEntrada: any,
  dias: any,
  diaBase: any,
): any {
  // Parsing and formating dtaEmissao and dtaEntrada with timezone
  const parsedDateEmissao = parseISO(dataEmissao);
  const znDateEmissao = zonedTimeToUtc(parsedDateEmissao, 'America/Sao_Paulo');
  const parsedDateEntrada = parseISO(dataEntrada);
  const znDateEntrada = zonedTimeToUtc(parsedDateEntrada, 'America/Sao_Paulo');

  const weekDays = [
    'Sunday',
    'Saturday',
    'Friday',
    'Thursday',
    'Wednesday',
    'Tuesday',
    'Monday',
  ];

  const getDayName = (dayNumber: number) => {
    switch (dayNumber) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        break;
    }
  };
  const getDaysInMonth = (dtaDeEmissao: string) => {
    const data = new Date(dtaDeEmissao);
    const maxDay = new Date(
      data.getFullYear(),
      data.getMonth() + 1,
      0,
    ).getDate();
    return maxDay;
  };
  const retornaDataValida = (data: string) => {
    let dia: number;
    let mes: number;
    let ano: number;

    if (data.length === 10) {
      const znData = zonedTimeToUtc(data, 'America/Sao_Paulo');
      if (String(znData) === 'Invalid Date') {
        const dateValidation = data.split('-');
        if (Number(dateValidation[1]) === 2 && Number(dateValidation[2]) > 28) {
          return `${dateValidation[0]}-${dateValidation[1]}-${28}`;
        }
      }
      dia = znData.getDate();
      mes = znData.getMonth() + 1;
      ano = znData.getFullYear();
      if (mes === 2 && dia > 28) {
        return `${ano}-${mes}-${28}`;
      }
      return data;
    }
    return data;
  };

  const aVista = () => {
    return znDateEmissao;
  };
  const diasDaData = () => {
    if (diaBase === '0')
      return add(znDateEmissao, {
        days: Number(dias),
      });
    return add(znDateEmissao, {
      days: Number(dias),
    }); // Retorna o mesmo valor pois será alterado no futuro
  };
  const diasDaDataLiquido = () => {
    // DD Liq
    const result = add(znDateEntrada, {
      days: Number(dias),
    });
    return result;
  };
  const diasForaSemana = () => {
    // DD f/Semana
    const weekDay = znDateEmissao.getDay();

    for (let i = 0; i < weekDays.length; i++) {
      if (getDayName(weekDay) === weekDays[i]) {
        const newNumCond = Number(dias) + i;
        const result = add(znDateEmissao, {
          days: Number(newNumCond),
        });
        return result;
      }
    }
    return znDateEmissao;
  };
  const diasForaQuinzena = () => {
    // DD f/Quinzena
    const monthDay = znDateEmissao.getDate();
    if (monthDay <= 15) {
      const newNumCond = 15 - Number(monthDay) + Number(dias);
      const result = add(znDateEmissao, {
        days: Number(newNumCond),
      });
      return result;
    }
    const newNumCond =
      getDaysInMonth(format(znDateEmissao, 'dd/MM/yyyy')) - monthDay + dias;
    const result = add(znDateEmissao, {
      days: Number(newNumCond),
    });
    return result;
  };
  const diasForaMes = () => {
    // DD f/Mês
    const monthDay = znDateEmissao.getDate();
    const newNumCond =
      Number(getDaysInMonth(dataEmissao)) - Number(monthDay) + Number(dias);
    const result = add(znDateEmissao, {
      days: Number(newNumCond),
    });
    return result;
  };
  const diasForaDezena = () => {
    // DD f/Dezena
    const monthDay = znDateEmissao.getDate();
    if (monthDay <= 10) {
      const newNumCond = 10 - monthDay + Number(dias);
      const result = add(znDateEmissao, {
        days: Number(newNumCond),
      });
      return result;
    }
    if (monthDay > 10 && monthDay <= 20) {
      const newNumCond = 20 - monthDay + Number(dias);
      const result = add(znDateEmissao, {
        days: Number(newNumCond),
      });
      return result;
    }
    const newNumCond =
      Number(getDaysInMonth(dataEmissao)) - monthDay + Number(dias);
    const result = add(znDateEmissao, {
      days: Number(newNumCond),
    });
    return result;
  };
  const dataPagPreFixada = () => {
    if (Number(dias) > 0) {
      if (znDateEmissao.getDate() >= Number(dias)) {
        const newDate = retornaDataValida(
          `${format(
            add(znDateEmissao, {
              months: 1,
            }),
            'yyyy-MM',
          )}-${dias.padStart(2, 0)}`,
        );
        const parsednewDate = parseISO(newDate);
        const znNewDate = zonedTimeToUtc(parsednewDate, 'America/Sao_Paulo');
        const result = znNewDate;
        return result;
      }
      const newDate = retornaDataValida(
        `${format(znDateEmissao, 'yyyy-MM')}-${dias.padStart(2, 0)}`,
      );
      const parsednewDate = parseISO(newDate);
      const znNewDate = zonedTimeToUtc(parsednewDate, 'America/Sao_Paulo');
      const result = znNewDate;
      return result;
    }
    return znDateEmissao;
  };
  const diasUteis = () => {
    return format(znDateEmissao, 'dd/MM/yyyy');
  };
  const diaFixo = () => {
    return format(znDateEmissao, 'dd/MM/yyyy');
  };
  const diaUtilFixo = () => {
    return format(znDateEmissao, 'dd/MM/yyyy');
  };

  /**
   * LEGENDA
   * weekDay => Dia da semana (0 a 6)
   * monthDay => Dia do mês (vária de acordo com a quantidade de dias do mês informado)
   * getDaysInMonth => Retorna o último dia do mês informado
   * getDayName => Retorna o nome do dia da semana
   */

  switch (String(cod_condicao)) {
    case '1': {
      return aVista();
    }
    case '2': {
      return diasDaData();
    }
    case '3': {
      return diasDaDataLiquido();
    }
    case '4': {
      return diasForaSemana();
    }
    case '5': {
      return diasForaQuinzena();
    }
    case '6': {
      return diasForaMes();
    }
    case '7': {
      return diasForaDezena();
    }
    case '8': {
      return dataPagPreFixada();
    }
    /**
     * 9, 10, 11, 12, 13 e 14 deverão ser desenvolvidos no futuro
     */
    case '9': {
      return aVista();
    }
    case '10': {
      return aVista();
    }
    case '11': {
      return aVista();
    }
    case '12': {
      return diasUteis();
    }
    case '13': {
      return diaFixo();
    }
    case '14': {
      return diaUtilFixo();
    }
    default: {
      const result = add(znDateEmissao, {
        days: Number(dias),
      });

      return result;
    }
  }
}
