/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-const */
/* eslint-disable operator-assignment */

import { fltRound, moneyFormat, transformAsCurrency } from '~/utils/functions';
import { Fornecedor, PerfilProps, Produto } from '../../protocols';
import {
  calculaCustoTotalSemST,
  calculaCustoTotSemIPIST,
  calculaICMS,
  calculaPMZ,
  calculaQtdRateioItemBruto,
  calculaReducaoBaseCalculo,
  calculaValorRateioItemBruto,
  calculaValorST,
  calculaValorTabelaFinal,
  calculaValorTabelaLiquida,
  calculaVendaVarejo,
} from '../Calculos';

/**
 * Params
 * @param pOperacaoInterestadual isOperacaoInterestadual
 * @param VTotLiqItensSubst valorTotalLiquidoDeItensDeSubstituicao
 * @param VTotFimItensICMSA valorTotalDeItensICMSA
 * @param QTotICMSAntecItens quantidadeTotalItensICMSA
 * @param pICMSRetido isICMSRetido
 * @param produto Dados do Produto
 * @param fornecedor Dados do Fornecedor
 * @param perfil Dados do P erfil
 * @param parametroLoja Parametros da Loja
 * @param Loja Dados da Loja
 */
/**
 * Variables
 * @param qtd_entrada_ant QTD_ENTRADA
 * @param qtd_saldo_dev QTD_TOTAL
 * @param val_indeniza utilizar val_indeniza = 0
 */
/**
 * Functions
 * @param calculaValorTabelaLiquida calcValTabLiq
 * @param calculaReducaoBaseCalculo baseCalculoReducao
 * @param calculaCustoTotalSemST CalcCustoTotSemSUBS
 * @param calculaVendaVarejo CalcVendaVarejo
 * @param calculaValorST CalcValSubstituicao
 * @param calculaValorTabelaFinal CalcValTabFinal
 * @param calculaValorRateioItemBruto ValRatItemBruto
 * @param calculaQtdRateioItemBruto QtdRatItemBruto
 * @param calculaVendaVarejo CalcVendaVarejo
 * @param calculaPMZ CalcPMZ
 */

function roundToTwoDecimalPlaces(n: number) {
  const numberRound = Math.round(n * 100) / 100;
  return Number(numberRound.toFixed(2));
}
export function CalculaCamposCalculados(
  pOperacaoInterestadual: boolean,
  VTotLiqItensSubst: any = 0,
  VTotFimItensICMSA: any = 0,
  QTotICMSAntecItens: any = 0,
  pICMSRetido: any = 0,
  produto: Produto,
  valTotalNF: number,
  fornecedor: Fornecedor,
  perfil: PerfilProps,
  parametroLoja: any,
  Loja: any,
  edicao = false,
): Produto {
  // calculo de desconto proporcional
  // quantidade unitario
  const desconto = produto.val_desconto * produto.qtd_devolucao;
  const Acrescimo = produto.val_despesa_acessoria * produto.qtd_devolucao;
  const frete = produto.val_frete * produto.qtd_devolucao;
  const ipi = produto.val_ipi * produto.qtd_devolucao;
  const st = produto.val_icms_st * produto.qtd_devolucao;

  produto.val_tot_icms_st = st;
  produto.val_tot_desconto = edicao ? produto.val_tot_desconto : desconto;
  produto.val_tot_despesa_acessoria = edicao
    ? produto.val_tot_despesa_acessoria
    : Acrescimo;
  produto.val_tot_frete = edicao ? produto.val_tot_frete : frete;
  produto.val_tot_ipi = ipi;

  // quantidade totais
  const val_bc_icms_unitario =
    produto.val_bc_icms / (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_bc_icms = val_bc_icms_unitario * produto.qtd_devolucao;

  const val_venda_varejo_unitario =
    produto.val_venda_varejo /
    (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_venda_varejo = val_venda_varejo_unitario * produto.qtd_devolucao;

  const val_bc_st_unitario =
    produto.val_bc_st / (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_bc_st = val_bc_st_unitario * produto.qtd_devolucao;

  const val_fcp_st_unitario =
    produto.val_fcp_st / (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_fcp_st = val_fcp_st_unitario * produto.qtd_devolucao;

  const val_fcp_icms_unitario =
    produto.val_fcp_icms / (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_fcp_icms = val_fcp_icms_unitario * produto.qtd_devolucao;

  const val_icms_unitario =
    produto.val_icms / (produto.qtd_entrada_ant * produto.qtd_embalagem);
  const val_icms = val_icms_unitario * produto.qtd_devolucao;

  produto.val_bc_icms = val_bc_icms;
  produto.val_bc_icms_unitario = val_bc_icms_unitario;

  produto.val_venda_varejo = val_venda_varejo;
  produto.val_venda_varejo_unitario = val_venda_varejo_unitario;

  produto.val_bc_st = val_bc_st;
  produto.val_bc_st_unitario = val_bc_st_unitario;

  produto.val_fcp_st = val_fcp_st;
  produto.val_fcp_st_unitario = val_fcp_st_unitario;

  produto.val_fcp_icms = val_fcp_icms;
  produto.val_fcp_icms_unitario = val_fcp_icms_unitario;

  produto.val_icms = val_icms;
  produto.val_icms_unitario = val_icms_unitario;

  produto.qtd_embalagem = 1;
  produto.qtd_devolucao = transformAsCurrency(produto.qtd_devolucao);
  let pRegraImpostoFlgNaoCredPISCof = produto.flg_nao_pis_cofins;
  if (produto.flg_ignora_pis_cofins) {
    pRegraImpostoFlgNaoCredPISCof = true;
  }

  // inicializando variáveis
  let valorTotalTabela = 0;
  let valorTotalTabelaLiq = 0;
  let valorTotalTabelaFinal = 0;
  let valorTotalTabelaFiscal = 0;
  let valorTotalTabelaLiqParaCalcICMS = 0;
  let valorPeso = 0;
  let valorRedBaseDeCalculoEntrada = produto.val_red_bc_ent_nf;
  // let valorRateio: number = 0;
  // let vTipoCusto: number = 0;
  let vValCredPISCofins = 0;
  let vValCredICMS = 0;
  let vValImpostoCredito = 0;
  let vQtdTotal = 0;
  // let vValCustoPISCofins: number = 0;
  let vTotalST = 0;
  let vTotalSTFCP = 0;
  let vTotalVendaVarejo = 0;
  // let vTotalLiquidoNF: number = 0;
  let vTotalBcST = 0;
  let vTotalSTCred = 0;
  let vTotalIPI = 0;
  // let Dica: string = "";
  let vRatDescNaoBC = 0;
  let vRatIndItem = 0;
  let vRatIndNF = 0;
  let vRatFrDest = 0;
  let vRatSTFrDest = 0;
  let vCustoTotalSemST = 0;
  let vCustoTotalSemIPISemSTSemFrete = 0;
  let vValCredICMSFreteDest = 0;
  let vValCredPISCOFINSFreteDest = 0;
  let CBxIPIBC = false;

  produto.per_icms_benef_cred = 0;
  produto.val_indeniza_rat = 0;
  produto.val_indenizacao = Number(produto.val_indenizacao) || 0;
  produto.val_acrescimo = Number(produto.val_acrescimo) || 0;

  produto = Object.assign(produto, {
    val_total: 0,
    val_tabela_liq: 0,
    val_tabela_final: 0,
    val_bc_icms: 0,
    val_icms: 0,
    val_isento_icms: 0,
    val_outros_icms: 0,
  });

  /**
   * Zerar variáveis totais
   */
  produto.val_total = 0;
  produto.val_tabela_liq = 0;
  produto.val_tabela_final = 0;
  if (!produto.flg_nao_calc_bcicms) {
    produto.val_icms = 0;
  }
  /**
   * Zerar variáveis custo
   */
  produto.val_reducao_base_calculo = 0;
  produto.val_reducao_base_calculo_sai_prod = 0;
  /**
   * Critérios para não realizar os cálculos
   */
  if (
    produto.qtd_embalagem === 0 ||
    produto.qtd_devolucao === 0 ||
    produto.val_tabela === 0
  ) {
    return produto;
  }

  valorPeso = produto.val_peso === 0 ? 1 : produto.val_peso;
  produto.qtd_total = produto.qtd_devolucao * produto.qtd_embalagem;
  // Não sei se as 2 linhas abaixo se fazem necessárias uma vez que o cálculo foi feito no select (acredito eu)
  vQtdTotal = (produto.qtd_devolucao * produto.qtd_embalagem) / valorPeso;
  produto.qtd_saldo_dev = vQtdTotal;
  // Fim

  if (produto.flg_ipv === false) {
    if (valorPeso - Math.trunc(valorPeso) === 0) {
      produto.qtd_saldo_dev = roundToTwoDecimalPlaces(vQtdTotal);
    }
  }

  if (produto.qtd_saldo_dev === 0) {
    return produto;
  }
  produto.val_total = produto.qtd_devolucao * produto.val_tabela;

  /**
   * Valor Liquido
   */
  // VALOR LIQUIDO
  valorTotalTabelaLiq = calculaValorTabelaLiquida(
    produto.val_total,
    produto.val_tot_despesa_acessoria,
    produto.val_tot_desconto + produto.val_indenizacao,
    produto.val_acrescimo,
    produto.val_tot_frete,
  );

  valorTotalTabela = valorTotalTabelaLiq;

  valorTotalTabelaFiscal = valorTotalTabelaLiq;

  /**
   * CALCULAR O CREDITO DE ICMS
   * Com Pauta
   */
  if (
    produto.val_pauta_ipv > valorTotalTabelaLiq / vQtdTotal &&
    fornecedor.flg_pauta
  ) {
    valorTotalTabelaLiqParaCalcICMS = roundToTwoDecimalPlaces(
      produto.val_pauta_ipv * vQtdTotal,
    );
  } else {
    valorTotalTabelaLiqParaCalcICMS = roundToTwoDecimalPlaces(
      valorTotalTabelaFiscal + (CBxIPIBC ? produto.val_tot_ipi : 0),
    ); // |--> IPI na Base Cálculo
  }

  if (!produto.flg_nao_calc_bcicms) {
    produto.val_bc_icms = 0;
    produto.val_isento_icms = 0;

    switch (produto.tipo_tributacao_ent_nf) {
      case 0:
      case 2: {
        if (!fornecedor.flg_nao_transf_icms) {
          // Normal, Reducao
          produto.val_bc_icms = calculaReducaoBaseCalculo(
            valorTotalTabelaLiqParaCalcICMS,
            valorRedBaseDeCalculoEntrada,
          );
        }
        break;
      }
      case 1:
      case 8: {
        produto.val_isento_icms = valorTotalTabelaLiqParaCalcICMS;
        break;
      }
      default:
        break;
    }
  }

  // produto.val_outros_icms = 0;
  // produto.val_isento_icms = 0;

  if (produto.tipo_tributacao_ent_nf === 2) {
    // Red.BC
    if (
      produto.val_pauta_ipv > valorTotalTabelaLiq / vQtdTotal &&
      fornecedor.flg_pauta
    ) {
      produto.val_isento_icms = roundToTwoDecimalPlaces(
        valorTotalTabelaFiscal - produto.val_bc_icms,
      );
    } else {
      produto.val_isento_icms = roundToTwoDecimalPlaces(
        valorTotalTabelaLiqParaCalcICMS - produto.val_bc_icms,
      );
    }
  } else if (
    produto.val_pauta_ipv > valorTotalTabelaLiq / vQtdTotal &&
    fornecedor.flg_pauta
  ) {
    const val_tot_tab_fiscal_format = roundToTwoDecimalPlaces(
      valorTotalTabelaFiscal,
    );
    const val_bc_icms_format = roundToTwoDecimalPlaces(produto.val_bc_icms);
    produto.val_outros_icms = roundToTwoDecimalPlaces(
      val_tot_tab_fiscal_format - val_bc_icms_format,
    );
  } else {
    const val_tot_tab_liq_format = roundToTwoDecimalPlaces(
      valorTotalTabelaLiqParaCalcICMS,
    );
    const val_bc_icms_format = roundToTwoDecimalPlaces(produto.val_bc_icms);
    produto.val_outros_icms = roundToTwoDecimalPlaces(
      val_tot_tab_liq_format - val_bc_icms_format,
    );
  }

  if (
    produto.tipo_tributacao_ent_nf === 1 ||
    produto.tipo_tributacao_ent_nf === 8
  ) {
    // Isento ou Não Tributado
    produto.val_isento_icms = valorTotalTabelaLiqParaCalcICMS;
    produto.val_outros_icms = 0;
  }

  if (produto.val_outros_icms < 0) {
    produto.val_outros_icms = produto.val_outros_icms * -1;
  }

  if (produto.val_isento_icms < 0) {
    produto.val_isento_icms = produto.val_isento_icms * -1;
  }

  if (produto.val_outros_icms !== 0 && produto.val_isento_icms !== 0) {
    produto.val_outros_icms = 0;
    produto.val_isento_icms = produto.val_outros_icms;
  }

  /**
   * ATUALMENTE O CALC.ICMS ESTÁ RECAINDO SOMENTE NO DESCONTO. NÃO ENTRA O CREDITO PARA DESP. ACESS., ENCARG. FIN., INDENIZ E FRETE NF
   * Calcula o Crédito ICMS
   */
  if (
    valorTotalTabelaLiqParaCalcICMS !== 0 &&
    !fornecedor.flg_nao_transf_icms
  ) {
    switch (produto.tipo_tributacao_ent_nf) {
      case 0:
      case 2: {
        // Normal, Reducao
        produto.val_icms = calculaICMS(
          valorTotalTabelaLiqParaCalcICMS,
          produto.per_icms_ent_nf - produto.per_icms_benef_cred,
          0,
          produto.tipo_tributacao_ent_nf,
          false,
          false,
        );
        if (
          produto.flg_veda_cred === true ||
          produto.flg_veda_cred_lim === true
        ) {
          produto.val_icms = roundToTwoDecimalPlaces(
            produto.val_bc_icms * (produto.per_icms_ent_nf / 100),
          );
        }
        break;
      }
      // Calcula ICMS Retido !!!
      case 3: {
        produto.val_icms = 0;
        break;
      }
      default: {
        produto.val_icms = 0;
      }
    }
  }

  if (
    fornecedor.flg_regime_st &&
    pOperacaoInterestadual &&
    produto.val_icms_ent_nf > 0
  ) {
    produto.val_bc_icms = calculaReducaoBaseCalculo(
      valorTotalTabelaLiqParaCalcICMS,
      valorRedBaseDeCalculoEntrada,
    );
    produto.val_icms = roundToTwoDecimalPlaces(
      produto.val_bc_icms * (produto.val_icms_ent_nf / 100),
    );
    produto.val_outros_icms = 0;
  }

  /*
    BLOCO COMENTADO ATÉ O MORETTI EXPLICAR DA ONDE DEVERIA SER ESSE CliDsTributVAL_ICMS, SE É REFERENTE À TRIBUTAÇÃO DO FORNECEDOR SIMPLES
    if (
      FORNECEDOR.flg_optante_simples &&
      FORNECEDOR.FLG_NAO_TRANSF_ICMS === false &&
      FramSelProdNF.CliDsTributVAL_ICMS > 0
    ) {
      vValCredICMS = CalculaICMS(
        valorTotalTabelaLiqParaCalcICMS,
        FramSelProdNF.CliDsTributVAL_ICMS,
        00,
        0,
        false,
        false
      ) / vQtdTotal;
    } else {
      vValCredICMS = produto.VAL_ICMS / vQtdTotal;
    }
  */

  /**
   * A partir daqui deverá utilizar o PER_ICMS_ENT que substituirá o PER_ICMS_ENT_NF, pois iniciará o Cálculo para acerto dos CUSTOS.
   */
  produto.per_icms_ent_calc = produto.per_icms_ent_nf;
  if (produto.tipo_tributacao_sai_prod === 3) {
    // Quando é ST na SAIDA, na Entrada NF Fornec. Fora Estado terá o Crédito na ESCRITURA, mas não poderá ter o Crédito no CUSTO
    vValCredICMS = 0;
    produto.per_icms_ent_calc = 0;
  }

  /**
   * Optante pelo Simples não deve calcular icms na devolução
   */
  if (fornecedor.flg_optante_simples === true && !produto.flg_nao_calc_bcicms) {
    vValCredICMS = 0;
    produto.val_bc_icms = 0;
    produto.val_icms = 0;
    produto.per_icms_ent_calc = 0;
  }

  if (fornecedor.flg_nao_transf_icms === true && !produto.flg_nao_calc_bcicms) {
    vValCredICMS = 0;
    produto.val_icms = 0;
    produto.per_icms_ent_calc = 0;
  }

  /**
   * CALCULO SUBSTITUICAO E VENDA VAREJO
   */
  vTotalIPI = produto.val_tot_ipi;
  if (produto.val_pauta_ipv !== 0) {
    vTotalIPI = 0; // Não deve considerar IPI para cálculo da Venda Varejo!!
  }

  vCustoTotalSemST = calculaCustoTotalSemST(
    produto.val_total,
    1,
    // produto.val_desconto + produto.VAL_INDENIZA, utilizar VAL_INDENIZA = 0 na linha abaixo
    produto.val_tot_desconto + produto.val_indenizacao,
    // produto.VAL_ACRESCIMO, utilizar VAL_ACRESCIMO = 0 na linha abaixo
    produto.val_acrescimo,
    produto.val_tot_despesa_acessoria,
    vTotalIPI,
    produto.val_tot_frete,
    produto.val_frete_dest,
    // veja dica abaixo
    0, //= =>>> quando valor total nf for ZERO, deverá alimentar o valor já rateado do parâmetro ValFreteDest acima
    // BDevol(PERFIL.TIPO_NF in [3,12]),
    // perfil.TIPO_NF === 3 || perfil.TIPO_NF === 12,
    // false,
    fornecedor.flg_nao_desc_bc_st, // --> Parametro fornecedor.FLG_NAO_DESC_BC_ST para verificar se considerará o desconto no Débito (BC ST Saida)
    false,
    fornecedor.flg_regime_st,
  );

  vCustoTotalSemIPISemSTSemFrete = calculaCustoTotSemIPIST(
    produto.val_total,
    1,
    // produto.val_desconto + produto.VAL_INDENIZA, utilizar VAL_INDENIZA = 0 na linha abaixo
    produto.val_tot_desconto + produto.val_indenizacao,
    // produto.VAL_ACRESCIMO, utilizar VAL_ACRESCIMO = 0 na linha abaixo
    produto.val_acrescimo,
    produto.val_tot_despesa_acessoria,
    produto.val_tot_frete,
    false,
  ); // --> Sempre terá que descontar o desconto para calcular o Crédito (BC ST Entrada).

  const flg_nao_calc_st = true;
  if (flg_nao_calc_st) {
    produto.val_venda_varejo_calc = 0;
    produto.val_subst_calc = 0;
    vTotalST = produto.val_tot_icms_st;
    vTotalSTFCP = produto.val_fcp_st;
    vTotalBcST = produto.val_bc_st;
    vTotalVendaVarejo = produto.val_venda_varejo;
    vTotalSTCred = produto.val_st_cred;
    if (perfil.tipo_nf === 12) {
      vTotalVendaVarejo = 0;
      vTotalBcST = 0;
      vTotalSTFCP = 0;
    }
  } else {
    produto.val_venda_varejo_calc = calculaVendaVarejo(
      produto.per_pauta_iva,
      produto.per_pauta_iva_ncm,
      produto.val_pauta_ipv,
      vCustoTotalSemST,
      vQtdTotal,
      fornecedor.flg_pauta,
    );

    produto.val_subst_calc = calculaValorST(
      produto.val_venda_varejo_calc,
      produto.val_icms_ent_nf,
      produto.val_red_bc_ent_nf,
      produto.val_icms_ent_nf,
      produto.val_red_bc_ent_nf,
      produto.val_icms,
      produto.val_red_bc_sai_prod,
      produto.per_pauta_iva_ncm,
      produto.val_pauta_ipv,
      vCustoTotalSemIPISemSTSemFrete,
      produto.tipo_tributacao_ent_nf,
      produto.tipo_tributacao_ent_nf === 3
        ? produto.tipo_tributacao_ent_nf
        : produto.tipo_tributacao_sai_prod,
      produto.flg_veda_cred,
      fornecedor.flg_regime_st,
      fornecedor.flg_nao_transf_icms,
      fornecedor.flg_optante_simples,
      pOperacaoInterestadual,
      false, // BNovoCalcST
      0,
      vTotalSTCred,
    );

    vTotalST = produto.val_subst_calc;
    vTotalVendaVarejo = produto.val_venda_varejo_calc;
    vTotalBcST = produto.val_st_cred;
  }
  vValCredPISCofins =
    pRegraImpostoFlgNaoCredPISCof === true
      ? 0
      : (valorTotalTabelaFiscal *
          (produto.per_aliq_pis + produto.per_aliq_cofin)) /
        100 /
        vQtdTotal;

  produto.val_tabela_liq = valorTotalTabelaLiq;

  /**
   * VALOR FINAL
   * Encargos no Item
   */
  valorTotalTabelaFinal = calculaValorTabelaFinal(
    valorTotalTabela,
    valorTotalTabelaLiq,
    vTotalST + vTotalSTFCP,
    produto.val_tot_ipi,
    true,
  );

  valorTotalTabelaFiscal = calculaValorTabelaFinal(
    valorTotalTabela,
    valorTotalTabelaFiscal,
    false && !fornecedor.flg_regime_st ? 0 : vTotalST + vTotalSTFCP, // Quando Fora Estado c/ GUIA, não rateia ICMS Retido (substuição no Custo Fiscal), pois não vem na NF..
    produto.val_tot_ipi,
    true,
  );

  /**
   * Encargos para Rateio
   * Calculo pra rateio
   */
  let VRat = 0;
  if (!false || fornecedor.flg_regime_st) {
    // Quando GUIA, não rateia ICMS Retido (substuição no Custo Fiscal), pois não vem na NF..
    if (
      pICMSRetido !== 0 &&
      (produto.tipo_tributacao_ent_nf === 3 ||
        produto.tipo_tributacao_sai_prod === 3) &&
      vTotalST === 0
    ) {
      VRat = calculaValorRateioItemBruto(
        false,
        valorTotalTabelaLiq,
        pICMSRetido,
        VTotLiqItensSubst,
      );
    }

    VRat =
      VRat +
      calculaQtdRateioItemBruto(
        produto.flg_icms_antecipado,
        QTotICMSAntecItens,
        1,
        valorTotalTabelaLiq,
        VTotFimItensICMSA,
      );
  }

  valorTotalTabelaFiscal = valorTotalTabelaFiscal + VRat;
  valorTotalTabelaFinal = valorTotalTabelaFinal + VRat;

  // produto.VAL_TABELA_LIQ = valorTotalTabelaFiscal; // VAL_TABELA_LIQ ou VAL_TABELA_LIQ_FISC?
  produto.val_tabela_final = valorTotalTabelaFinal;

  /**
   * VALORES UNITÁRIOS
   * Inicia cálculos para valores de custo unitários !
   */
  if (produto.val_tabela === 0 || produto.qtd_embalagem === 0) {
    return produto;
  }

  produto.val_custo_tabela = produto.val_tabela / produto.qtd_embalagem;

  valorTotalTabelaLiq += VRat;

  produto.val_custo_sicms = valorTotalTabelaLiq / vQtdTotal - vValCredICMS;
  // A Qtd Total já esta sendo dividida pelo peso, não necessário multiplicar
  produto.val_custo_rep = valorTotalTabelaFinal / vQtdTotal;

  /**
   * CUSTO CHEIO É O CUSTO FISCAL
   */
  produto.val_custo_cheio = valorTotalTabelaFiscal / vQtdTotal;

  vValImpostoCredito = (vValCredICMS + vValCredPISCofins) * vQtdTotal;

  produto.val_imposto_credito = vValImpostoCredito;
  produto.val_custo_scred =
    (valorTotalTabelaFinal - vValImpostoCredito) / vQtdTotal;

  /**
   * FRETE DESTACADO (CF)
   */
  produto.val_bc_st_frete_dest = calculaVendaVarejo(
    produto.per_pauta_iva,
    produto.per_pauta_iva_ncm,
    0,
    produto.val_frete_dest,
    produto.qtd_saldo_dev,
    fornecedor.flg_pauta,
  );

  produto.val_icms_st_frete_dest =
    produto.val_bc_st_frete_dest * (produto.val_icms_ent_nf / 100);

  produto.val_bc_st_entrada = 0;
  produto.val_bc_st = 0;
  produto.val_st_cred = 0;
  produto.val_bc_st_guia_entrada = 0;
  produto.val_bc_st_guia_saida = 0;
  produto.val_venda_varejo_guia = 0;
  produto.val_st_guia = 0;

  if (produto.flg_veda_cred === true && produto.val_icms_ent_nf !== 0) {
    if (perfil.tipo_operacao === 1) {
      valorRedBaseDeCalculoEntrada =
        (1 -
          calculaReducaoBaseCalculo(
            produto.val_icms,
            produto.val_red_bc_sai_prod,
          ) /
            calculaReducaoBaseCalculo(
              produto.val_icms_ent_nf,
              produto.val_red_bc_ent_nf,
            )) *
        100; // Não pode pegar o PER_ICMS_ENT_NF, pois quando está fora UF é zerado.
    } else {
      valorRedBaseDeCalculoEntrada = 0;
    }
  } else {
    valorRedBaseDeCalculoEntrada = produto.val_red_bc_ent_nf;
  }

  valorRedBaseDeCalculoEntrada = fltRound(valorRedBaseDeCalculoEntrada, 2);

  // produto.VAL_REDUCAO_BASE_CALCULO_SAI_PROD =
  //   produto.VAL_REDUCAO_BASE_CALCULO_SAI_PROD;
  if (produto.tipo_tributacao_sai_prod === 3) {
    if (produto.flg_guia === false) {
      if (vTotalVendaVarejo !== 0) {
        produto.val_bc_st_entrada = calculaReducaoBaseCalculo(
          vCustoTotalSemIPISemSTSemFrete,
          valorRedBaseDeCalculoEntrada,
        );
      } else {
        produto.val_bc_st_entrada = 0;
      }
      produto.val_bc_st = calculaReducaoBaseCalculo(
        vTotalVendaVarejo,
        produto.val_red_bc_sai_prod,
      );
      if (vTotalBcST > 0) {
        produto.val_bc_st = vTotalBcST;
      }
      produto.val_tot_icms_st = vTotalST;
      if (
        produto.flg_veda_cred === true &&
        produto.val_icms_ent_nf !== 0 &&
        perfil.tipo_operacao === 1 &&
        produto.val_tot_icms_st > 0
      ) {
        vTotalSTCred =
          produto.val_bc_st_entrada * (produto.val_icms_ent_nf / 100);
      }
      produto.val_st_cred = vTotalSTCred;
    } else {
      if (fornecedor.flg_nao_transf_icms === true) {
        produto.val_bc_st_guia_entrada = 0;
      } else {
        produto.val_bc_st_guia_entrada = calculaReducaoBaseCalculo(
          vCustoTotalSemIPISemSTSemFrete,
          valorRedBaseDeCalculoEntrada,
        );
      }
      produto.val_bc_st_guia_saida = calculaReducaoBaseCalculo(
        vTotalVendaVarejo,
        produto.val_red_bc_sai_prod,
      );
      produto.val_venda_varejo_guia = vTotalVendaVarejo;
      produto.val_st_guia = vTotalST;
    }
  }

  // ***** R A T E I O  N O  F I N A L *****

  produto.val_produto = produto.val_tabela_liq;

  produto.val_nf = roundToTwoDecimalPlaces(
    produto.val_produto +
      produto.val_tot_ipi +
      produto.val_tot_icms_st +
      produto.val_fcp_st,
  );

  vQtdTotal = produto.qtd_devolucao * produto.qtd_embalagem;
  VRat = 0;

  vRatDescNaoBC = 0;

  produto.val_indeniza_rat = 0; // CalculoRatIndenizFinal(produto.VAL_NF.AsFloat, vTotalLiquidoNF);

  vRatIndNF = produto.val_indeniza_rat;
  vRatIndItem = produto.val_indenizacao * produto.qtd_saldo_dev;

  vRatFrDest = produto.val_frete_dest;

  vRatSTFrDest = 0;

  if (!parametroLoja.flg_ignora_st_cf_custo) {
    // Parametro para definir ser irá considerar o ST CF no Custo
    if (produto.tipo_tributacao_ent_nf === 3 && produto.flg_guia === false) {
      vRatSTFrDest = produto.val_icms_st_frete_dest;
      if (vTotalST === 0) {
        vRatSTFrDest = 0;
      }
    }
  }

  vValCredICMSFreteDest = 0;
  vValCredPISCOFINSFreteDest = 0;

  produto.per_icms_ent_cf = 0; // CliDStFretePER_ALIQ_ICMS;
  produto.val_imposto_cred_cf =
    (vValCredICMSFreteDest + vValCredPISCOFINSFreteDest) /
    produto.qtd_embalagem;

  produto.val_imposto_credito =
    (vValCredICMS +
      vValCredICMSFreteDest +
      vValCredPISCofins +
      vValCredPISCOFINSFreteDest) *
    vQtdTotal;
  produto.val_custo_scred =
    produto.val_tabela_final / vQtdTotal -
    (vValCredICMS +
      vValCredICMSFreteDest +
      vValCredPISCofins +
      vValCredPISCOFINSFreteDest);
  produto.val_custo_pmz = calculaPMZ(
    produto.val_custo_scred,
    produto.val_icms_sai_prod,
    Loja.per_desp_op,
    0,
    0,
    produto.flg_nao_pis_cofins,
    0,
  );

  // produto.VAL_FINAL_ITEM = fltRound(
  //   produto.VAL_TABELA_FINAL / produto.qtd_entrada_ant,
  //   2,
  // );

  if (!produto.flg_nao_calc_bcicms) {
    produto.val_outros_icms =
      produto.val_nf - // ---> Subtraindo do total NF, para entrar também os complementos que não consideram BC
      (produto.val_bc_icms +
        produto.val_isento_icms +
        produto.val_tot_icms_st +
        produto.val_fcp_st +
        (CBxIPIBC ? 0 : produto.val_tot_ipi));

    if (produto.val_outros_icms < 0) {
      produto.val_outros_icms = 0;
    }
  }

  /**
   * Arredondar tudo para 2 casas decimais
   */
  // produto.VAL_TABELA_LIQ = fltRound(Number(produto.VAL_TABELA_LIQ), 2);
  // produto.VAL_FINAL_ITEM = fltRound(produto.VAL_FINAL_ITEM, 2);
  // produto.VAL_TABELA_FINAL = fltRound(Number(produto.VAL_TABELA_FINAL), 2);
  // produto.VAL_ST = fltRound(Number(produto.VAL_ST), 2);
  // produto.VAL_ISENTO_ICMS = fltRound(Number(produto.VAL_ISENTO_ICMS), 2);
  // produto.VAL_BC_ICMS = fltRound(Number(produto.VAL_BC_ICMS), 2);
  // produto.val_nf = fltRound(Number(produto.val_nf), 2);
  // produto.VAL_OUTROS_ICMS = fltRound(Number(produto.VAL_OUTROS_ICMS), 2);
  // produto.val_bc_st_entrada = fltRound(Number(produto.val_bc_st_entrada), 2);
  // produto.val_bc_st = fltRound(Number(produto.val_bc_st), 2);
  // produto.VAL_ST_CRED = fltRound(Number(produto.VAL_ST_CRED), 2);
  // produto.val_bc_st_guia_entrada = fltRound(
  //   Number(produto.val_bc_st_guia_entrada),
  //   2,
  // );
  // produto.val_bc_st_guia_saida = fltRound(
  //   Number(produto.val_bc_st_guia_saida),
  //   2,
  // );
  // produto.val_venda_varejo_guia = fltRound(
  //   Number(produto.val_venda_varejo_guia),
  //   2,
  // );
  // produto.VAL_ST_GUIA = fltRound(Number(produto.VAL_ST_GUIA), 2);
  // produto.val_bc_st_frete_dest = fltRound(
  //   Number(produto.val_bc_st_frete_dest),
  //   2,
  // );
  // produto.val_icms_st_frete_dest = fltRound(
  //   Number(produto.val_icms_st_frete_dest),
  //   2,
  // );

  produto.val_icms_st = produto.val_tot_icms_st / produto.qtd_devolucao;
  return produto;
}
