import { transformAsCurrency } from '~/utils/functions';
import { Produto } from '../../protocols';

export interface Subtotais {
  totalProdutos: number;
  totalST: number;
  fcpSt: number;
  guiaSt: number;
  ipi: number;
  icmsOp: number;
  frete: number;
  totalNf: number;
  bcIcmsOp: number;
}

export function procedureCalculaSubtotal(produtos: Produto[]): Subtotais {
  let totalProdutos = 0; // Valor somado de todos os itens
  let totalST = 0; // Valor da Substituição Tributária
  let fcpSt = 0; // Valor Fundo de combate a pobreza ST
  let guiaSt = 0; // Valor Guia ST
  let ipi = 0; // Valor do IPI
  let icmsOp = 0; // Valor do ICMS Operação Própria
  let frete = 0; // Valor do Frete
  let totalNf = 0; // Valor total da NF
  let bcIcmsOp = 0; // Valor da Base de Cálculo do ICMS OP

  for (let i = 0; i < produtos.length; i++) {
    // Valor Total e Quantidade Total de Produtos (Para Rateio)

    if (produtos[i].qtd_embalagem !== 0) {
      // GUIA ST
      if (produtos[i].flg_guia) {
        guiaSt += produtos[i].val_st_guia;
      } else {
        totalST += produtos[i].val_tot_icms_st;
        fcpSt += produtos[i].val_fcp_st;
      }

      // Base Cálculo ICMS
      bcIcmsOp += produtos[i].val_bc_icms;
      icmsOp += produtos[i].val_icms;

      ipi += produtos[i].val_tot_ipi;

      frete += produtos[i].val_frete_dest + produtos[i].val_tot_frete;

      // Total Final
      totalProdutos += Number(produtos[i].val_produto.toFixed(2));
      totalNf += Number(produtos[i].val_nf.toFixed(2));
    }
  }
  totalProdutos = transformAsCurrency(totalProdutos);
  totalST = transformAsCurrency(totalST);
  fcpSt = transformAsCurrency(fcpSt);
  guiaSt = transformAsCurrency(guiaSt);
  ipi = transformAsCurrency(ipi);
  icmsOp = transformAsCurrency(icmsOp);
  frete = transformAsCurrency(frete);
  totalNf = transformAsCurrency(totalNf);
  bcIcmsOp = transformAsCurrency(bcIcmsOp);

  return {
    totalProdutos,
    totalST,
    fcpSt,
    guiaSt,
    ipi,
    icmsOp,
    frete,
    totalNf,
    bcIcmsOp,
  };
}
