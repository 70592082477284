/**
 * @solidus - CalcValTabLiq
 */

export function calculaValorTabelaLiquida(
  valTabela: number,
  valDespAcess: number,
  valDesconto: number,
  valAcrescimo: number,
  valFrete: number,
): number {
  if (valTabela === 0) return 0;
  const valEncargos = valAcrescimo + valDespAcess + valFrete - valDesconto;
  const liquido = valTabela + valEncargos;

  const numberRound = Math.round(liquido * 100) / 100;
  return Number(numberRound.toFixed(2));
}
