import { toast } from 'react-toastify';
import { Produto } from '../../protocols';
import getTributacoes from '../../services/getTributacoes';
import { calculaICMS } from '../Calculos';

export async function DefinirTributacao(
  produto: Produto,
  ufLoja: string | null,
  ufFornecedor: string | null,
) {
  const tributacoes = await getTributacoes();
  let tipoTributacao = 0;
  if (produto.val_red_bc_ent_nf > 0) {
    tipoTributacao = 2; // Redução
  }
  // Tributação Entrada
  if (
    (produto.tipo_tributacao_ent_nf === 3 &&
      produto.val_icms_ent_nf > 0 &&
      produto.val_icms_st > 0 &&
      ufLoja !== 'MS' &&
      ufLoja !== 'RJ') ||
    (ufLoja === 'MS' && produto.tipo_tributacao_ent_nf === 3)
  ) {
    const DataTributacoes = tributacoes.find(
      (item: any) =>
        item.tipo_tributacao === tipoTributacao &&
        item.val_reducao === produto.val_red_bc_ent_nf &&
        item.val_icms === produto.val_icms_ent_nf,
    );

    if (DataTributacoes) {
      produto.cod_tributacao = DataTributacoes.cod_tributacao;
      produto.val_icms_ent_nf = DataTributacoes.val_icms;
      produto.tipo_tributacao_ent_nf = DataTributacoes.tipo_tributacao;
      produto.val_red_bc_ent_nf = DataTributacoes.val_reducao;
      if (produto.flg_veda_cred) {
        if (ufLoja === ufFornecedor) {
          produto.per_icms_ent_nf = calculaICMS(
            0,
            DataTributacoes.val_icms,
            DataTributacoes.val_reducao,
            DataTributacoes.tipo_tributacao,
            true,
            false,
          );
          produto.cod_sit_tributaria = DataTributacoes.cod_situacao_tributaria;
        } else {
          produto.per_icms_ent_nf = calculaICMS(
            0,
            DataTributacoes.val_icms,
            0,
            DataTributacoes.tipo_tributacao,
            true,
            false,
          );
          produto.cod_sit_tributaria = '000';
          produto.val_red_bc_ent_nf = 0;
        }
      } else {
        produto.per_icms_ent_nf = calculaICMS(
          0,
          DataTributacoes.val_icms,
          DataTributacoes.val_reducao,
          DataTributacoes.tipo_tributacao,
          true,
          false,
        );
        produto.cod_sit_tributaria = DataTributacoes.cod_situacao_tributaria;
      }
    } else {
      toast.warning(
        'Não existe nenhum código de tributação que tenha mesmo Perc. de ICMS e Red.BC para Tipo Tributação Tributado',
      );
      return undefined;
    }
  } else {
    const Tributacoes = tributacoes.find(
      (item: any) => item.cod_tributacao === produto.cod_tributacao,
    );
    if (Tributacoes) {
      produto.cod_tributacao = Tributacoes.cod_tributacao;
      produto.val_icms_ent_nf = Tributacoes.val_icms;
      produto.tipo_tributacao_ent_nf = Tributacoes.tipo_tributacao;
      produto.val_red_bc_ent_nf = Tributacoes.val_reducao;

      if (produto.flg_veda_cred || produto.flg_veda_cred_lim) {
        produto.per_icms_ent_nf = calculaICMS(
          0,
          Tributacoes.val_icms,
          0,
          Tributacoes.tipo_tributacao,
          true,
          false,
        );
        if (!produto.flg_veda_cred_lim && Tributacoes.val_reducao === 0) {
          produto.cod_sit_tributaria = '000';
          produto.val_red_bc_ent_nf = 0;
        }
      } else {
        produto.per_icms_ent_nf = calculaICMS(
          0,
          Tributacoes.val_icms,
          Tributacoes.val_reducao,
          Tributacoes.tipo_tributacao,
          true,
          false,
        );
        produto.cod_tributacao = Tributacoes.cod_tributacao;
        produto.cod_sit_tributaria = Tributacoes.cod_situacao_tributaria;
      }

      if (produto.val_st_cred > 0 && ufLoja !== 'MS' && ufLoja !== 'RJ') {
        if (produto.val_red_bc_ent_nf > 0) {
          produto.cod_sit_tributaria = '020';
        } else {
          produto.cod_sit_tributaria = '000';
        }
      }
    }
  }
  return produto;
}
