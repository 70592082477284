import React from 'react';
import { DevolucaoSaidaNFEFornContent } from './DevolucaoSaidaNFEFornContent';
import { DevolucaoSaidaNFEFornContextProvider } from './DevolucaoSaidaNFEFornContext';
import { TransmissaoProvider } from '~/components/Nfe/TransmissaoNFe/Transmissao';

/**
 * Nessa tela será utilizada a abreviação Forn para Fornecedor
 */

export const DevolucaoESaidaNfForn: React.FC = () => {
  return (
    <TransmissaoProvider>
      <DevolucaoSaidaNFEFornContextProvider>
        <DevolucaoSaidaNFEFornContent />
      </DevolucaoSaidaNFEFornContextProvider>
    </TransmissaoProvider>
  );
};
